import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { AppRegistry } from 'react-native';

import '@utils/polyfill';
import '@utils/dayJsPlugins';

import * as data from './app.json';

const appData = data;
//It's recommended to wrap <React.StrictMode>, but there are currently some issues with Animation,
// resulting in several bad behaviors in the app. The simplest approach now is to remove Strict Mode.
//Recommended: const AppWrapper: React.FC = () => <React.StrictMode><App /></React.StrictMode>;

const App = React.lazy(() => import('./App'));

const AppWrapper: React.FC = () => <Suspense><App /></Suspense>;


// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

AppRegistry.registerComponent(appData.name, () => AppWrapper);

//This seems to work better with React 18 than AppRegistry.runApplication
root.render(<AppWrapper />);
